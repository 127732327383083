<template>
  <div>
    <a-row type="flex" style="flex-wrap: nowrap;">
      <a-col flex="300px" :span="6">
        <a-button type="link" icon="folder-add" @click="create" block style="font-size: 18px;  height: auto; padding: 16px; text-align: left">新建分组</a-button>
        <favorite-groups ref="favoriteGroups" :type="2" @select="onSelect" style="max-height: 760px;overflow: auto"></favorite-groups>
      </a-col>
      <a-col flex="auto" style="padding: 16px; overflow-x: hidden;">
        <v-table ref="table" :columns="columns" :remote="remote" :query="query" :pagination="pagination" :scroll="{ x: 800 }" row-key="awemeId">
          <template #code="{row}">
            <aweme-card :value="row" :dataType="$root.dataType" style="max-height: 200px"></aweme-card>
          </template>
          <template #operation="{row}">
            <!-- todo -->
            <router-link :to="{ name: 'aweme:analysis', params: { awemeId: row.awemeId } }" target="_blank">视频分析</router-link>
            <a-divider type="vertical" />
            <a type="link" @click="cancelFavorite(row)">取消收藏</a>
          </template>
        </v-table>
      </a-col>
    </a-row>
    <group-form v-model="visible" ref="GroupForm" @saved="onGroupSaved" :type="2"></group-form>
  </div>
</template>
<style scoped>
.ant-table .ant-list-item {
  padding: 0;
}

</style>
<script>

import AwemeCard from "@/components/widgets/aweme-card";
import FavoriteGroups from "@/components/widgets/favorite-groups";
import GroupForm from "@/components/widgets/group-form";


let defaultQuery = {
  orderBys: "ctime|desc,awemeId|desc",
  param: {
    groupId: ""
  }
}
export default {
  components: { GroupForm, AwemeCard, FavoriteGroups },
  data() {
    return {
      orderDateRange: [],
      query: _.cloneDeep(defaultQuery),
      loading: false,
      loadingSummary: false,
      pagination: { pageSize: 10 },
      visible: false,
    }
  },
  computed: {
    columns() {
      return [
        { title: "视频内容", dataIndex: "code", scopedSlots: { customRender: 'code' }, width: 320, ellipsis: true },
        { title: "播放量", dataIndex: "playVolume", customRender: this.$dict.humanReadable, width: 80 },
        { title: "点赞量", dataIndex: "diggCount", customRender: this.$dict.humanReadable, width: 80},
        { title: "评论量", dataIndex: "commentCount", customRender: this.$dict.humanReadable, width: 80 },
        { title: "转发量", dataIndex: "shareCount", customRender: this.$dict.humanReadable, width: 80 },
        { title: "操作", scopedSlots: { customRender: "operation" }, width: 150 }
      ]
    }
  },
  methods: {
    create() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.GroupForm.$emit("init", {});
      });
    },
    onGroupSaved() {
      this.$refs.favoriteGroups.load();
    },
    onSelect(group = {}) {
      this.query.param.groupId = group.id || 0
      group.id ? this.search() : this.clean()
    },
    groupAnalysis() {
      this.$router.push({ name: 'groupAnalysis', params: { groupId: this.query.param.groupId } })
    },
    cancelFavorite(row) {
      this.$confirm({
        title: '取消收藏确认',
        content: () => <div style="color:red;font-size: 15px;padding: 8px 0">
          您确定要取消收藏该视频吗?</div>,
        onOk:()=> {
          this.$api.FavoriteService.unFavorite(row.awemeId,this.query.param.groupId).then(() => {
            this.$message.info("取消成功！")
            this.search()
            this.$refs.favoriteGroups.load();
          })
        }
      })
    },
    clear() {
      this.query = _.cloneDeep(defaultQuery);
      this.$nextTick(() => {
        this.search(true);
      });
    },
    init(options = {}) {
      this.$refs.favoriteGroups.load()
    },
    remote(qp) {
      return this.$api.FavoriteService.findFavoriteBiz(2, qp);
    },
    search(reset = true) {
      this.$refs.table.load({ reset: reset });
    },
    clean() {
      this.$refs.table.clean()
    }
  },
  created() {},
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
