<template>
  <a-list
    class="favorite-groups"
    item-layout="horizontal"
    :data-source="groups"
  >
    <template #renderItem="row, i">
      <a-list-item :selected="row.id === selId" @click="select(row, i)">
        <a-list-item-meta
          :description="`${row.awemeCount || 0}个${
            type === 1 ? '帐号' : '视频'
          }`"
        >
          <template #title>
            <span :title="row.name || '-'">{{ row.name || "-" }}</span>
          </template>
          <template #avatar>
            <a-icon type="team" style="font-size: 20px"></a-icon>
          </template>
        </a-list-item-meta>
        <template #actions>
          <a-dropdown placement="bottomRight">
            <a-button
              type="link"
              shape="circle"
              icon="setting"
              @click.stop="(e) => e.preventDefault()"
              style="color: #000"
            />
            <template #overlay>
              <a-menu>
                <a-menu-item @click="changeName(row)">修改名称</a-menu-item>
                <a-menu-item @click="del(row)">删除分组</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-list-item>
    </template>
    <div
      v-if="!groups.length"
      style="
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      暂无分组，<a @click="createGroup">去创建</a>
    </div>
    <a-modal
      v-model="visible"
      :title="`${judgeType == 1 ? '编辑' : '新建'}分组`"
      :confirmLoading="loading"
      @ok="saveName"
    >
      <a-form-model
        ref="form"
        :model="entity"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="分组名" prop="name">
          <a-input
            style="height: 85%"
            v-model="entity.name"
            placeholder="请输入"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-list>
</template>



<script>
import _http from "@/api/personalcenter";
export default {
  name: "favorite-group",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      visible: false,
      selId: 0,
      groups: [],
      authorCounts: {},
      name: "",
      desc: 1,
      loading: false,
      editData: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      entity: {},
      rules: {
        name: [
          {
            required: true,
            message: "组名不能为空",
            trigger: "blur",
          },
          { max: 15, message: "组名最多15个字符", trigger: "change" },
        ],
      },
      judgeType: 0,
    };
  },
  methods: {
    select(row = {}, idx) {
      // console.log('rowsdfs',row)
      if(row.id){
        this.selId = row.id
        this.$emit("select", row)
      }else{
        return
      }
      // this.selId !== row.id &&
      //   ((this.selId = row.id), this.$emit("select", row));
    },
    changeName(row) {
      this.visible = true;
      this.judgeType = 1;
      this.editData = row;
    },
    del(row) {
      this.$confirm({
        title: "提示",
        content: "确定删除此分组吗？",
        onOk: () => {
          _http.getAwemeGroupDelete(row.id).then(() => {
            this.$message.info("删除成功！");
            this.load();
            this.select();
          });
        },
      });
    },
    createGroup() {
      this.visible = true;
      this.judgeType = 0;
    },
    load() {
      this.$api.FavoriteGroupService.query().then((res) => {
        // console.log(res.data);
        this.groups = res.data || [];
        this.groups.reverse();
        this.groups.every((t) => t.id !== this.selId) &&
          this.select(this.groups[0], 0);
      });
    },
    init() {
      this.load();
    },
    saveName() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let payload = {
          groupId: this.editData.id,
          name: this.entity.name.trim(),
        };
        if(!this.judgeType) delete payload.groupId;
        let http_api = ['createNewGroup','getAwemeGroupRename']
        _http[http_api[this.judgeType]](payload).then((res) => {
          if (res.code == 200) {
            this.load();
            this.visible = false
          }else{
            this.$message.error(res.msg)
          }
        }).finally(()=>{
          this.loading = false
        });
      });
    },
  },
  mounted() {
    this.$on("init", this.init);
  },
};
</script>
<style scoped>
.favorite-groups {
  overflow: hidden;
}
.favorite-groups:hover {
  overflow-y: auto;
}
.ant-list-item {
  cursor: pointer;
  padding-left: 16px;
}

.ant-list-item:hover {
  background: #e6f7ff;
}

.ant-list-item[selected]:not(:hover) {
  background: #ebeaea;
}
.ant-list-item-meta,
.ant-list-item-meta-content {
  overflow: hidden;
}
.ant-list-item-meta-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
