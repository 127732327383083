<template>
  <a-list-item :small="small">
    <a-list-item-meta>
      <template #title>
        <!-- todo -->
        <router-link v-if="link" class="aweme-card-title"
                     :to="{ name: `${type}:analysis`, params: { [`${{aweme: type, live: 'room'}[type]}Id`]: wrapValue.id }, query: {author_id: value.authorId} }"
                     :title="wrapValue.title || '-'" target="_blank">{{ wrapValue.title || "-" }}
        </router-link>
        <!-- <div v-if="link" class="aweme-card-title">{{ wrapValue.title || '-' }}</div> -->
        <span v-else class="aweme-card-title" :title="wrapValue.title || '-'">{{ wrapValue.title || "-" }}</span>
      </template>
      <a-avatar slot="avatar" :src="wrapValue.cover" class="avator-cover"/>
      <div slot="description" class="card-desc">
        <span v-if="!small" :title="wrapValue.authorNickname || '-'">{{
            `播主：${ wrapValue.authorNickname || "-" }`
          }}</span>
        <span v-if="!small" :title="wrapValue.authorCode || '-'">{{
            `${ $dict.getText(dataType, $dict.store.DATATYPE) }号：${ wrapValue.authorCode || "-" }`
          }}</span>
        <span :title="wrapValue.publishTime || '-'">{{ `${ timeLabel }：${ wrapValue.publishTime || "-" }` }}</span>
        <div v-if="!small">
          <a-tag v-if="wrapValue.manufactorName" color="red">{{ wrapValue.manufactorName }}</a-tag>  <!--主机厂名称-->
          <a-tag v-if="wrapValue.dealerName" color="red">{{ wrapValue.dealerName }}</a-tag>          <!--经销商名称-->
        </div>
      </div>
    </a-list-item-meta>
  </a-list-item>
</template>

<script>

const defaultFieldMap = {
  "id": "awemeId",
  "authorNickname": "nickname",
  "authorCode": "code",
  "cover": "awemeCover",
  "title": "awemeTitle",
  "publishTime": "publishTime",
  "manufactorName": "manufactorName",
  "dealerName": "dealerName"
};

export default {
  name: "aweme-card",
  props: {
    value: {
      type: Object,
      default: () => {
      }
    },
    dataType: {
      type: Number,
      default: 1
    },
    small: {
      type: Boolean,
      default: false
    },
    fieldMap: {
      type: Object,
      required: false,
      default: () => _.cloneDeep(defaultFieldMap)
    },
    timeLabel: {
      type: String,
      required: false,
      default: "发布时间"
    },
    link: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "aweme"
    }
  },
  data: () => ({}),
  computed: {
    wrapValue() {
      let fieldMap = Object.assign({}, defaultFieldMap, this.fieldMap), r = {};
      for(let attr in fieldMap) {
        let orginKey = fieldMap[attr];
        let val = _.get(this.value, orginKey);
        r[attr] = val;
      }
      console.log(r);
      return r;
    }
  }
};
</script>

<style scoped>
.ant-list-item {
  display: block;
}

.ant-list-item-meta-content {
  overflow: hidden;
}

.ant-list-item-meta-title, .card-desc > span {
  /*max-width: 180px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.ant-list-item[small] {
  padding: 0;
}

.ant-list-item .avator-cover {
  width: 90px;
  height: 120px;
  border-radius: 0;
}

.ant-list-item[small] .avator-cover {
  height: 80px;
  border-radius: 0;
}

a.aweme-card-title:hover {
  color: #ff9900;
  cursor: pointer;
}

.card-desc {
  display: flex;
  flex-flow: column;
}
</style>
