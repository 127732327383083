<template>
  <a-modal v-model="visible" :title="`${entity.id ? '编辑' : '新建'}分组`" :confirmLoading="loading" @ok="save">
    <a-form-model ref="form" :model="entity" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="分组名" prop="name">
        <a-input style="height: 85%" v-model="entity.name" placeholder="请输入"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<style rel="stylesheet/less" scoped lang="less">
</style>
<script>

let defaultEntity = {id: null, name: ""};

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 18},
      entity: {...defaultEntity},
      loading: false,
      rules: {
        name: [{
          required: true, message: "组名不能为空", trigger: "blur"
        },
          {max: 15, message: "组名最多15个字符", trigger: "change"}]
      }
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if(!valid) {
          return;
        }
        this.loading = true;
        Object.assign(this.entity, {
          name: this.entity.name.trim(),
          type: this.type,
        });
        this.$api.FavoriteGroupService.save(this.entity).then(res => {
          console.log(res);
          this.visible = false;
          if(typeof res == "object") {
            if(res.code == 1) {
              this.$message.error("该分组名已存在");
            } else {
              this.$message.error(res.msg);
            }
          } else {
            this.$message.info("操作成功");
            this.$emit("saved", res);
          }
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    init(options = {}) {
      this.$refs.form.resetFields();
      this.entity = {...defaultEntity};
      if(options.record) {
        this.entity = options.record;
      }
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
